<template>
    <custom-modal
        width="80%"
        title="保单保函上传"
        :visible="visible"
        @on-close="handleClose"
    >
        <modal-content
            :order-no="orderNo"
            @on-close="handleClose"
            @on-refresh="$emit('on-refresh')"
        />

        <template #customFooter>
            <custom-portal-target :name="portalTarget" />
        </template>
    </custom-modal>
</template>
<script>
import CustomModal from '@components/CustomModal'
import CustomPortalTarget from '@components/CustomPortalVue/PortalTarget'

import ModalContent, { PORTAL_KEY } from './Content'

export default {
    name: 'PolicyUploadModal',
    components: {
        CustomModal,
        ModalContent,
        CustomPortalTarget
    },
    props: {
        visible: Boolean,
        orderNo: String
    },
    data() {
        return {
            portalTarget: PORTAL_KEY
        }
    },
    methods: {
        handleClose() {
            this.$emit('on-close')
        }
    }
}
</script>
