import { INST_TYPE_BANK, INST_TYPE_INSURANCE, GUARANTEE_TYPE_LIST, GUARANTEE_TYPE_GUARANTEE_INSURANCE } from '@constant/enum'

import { validatePhoneNumber } from '@weights/CreateForm/validate/validates'

const REQUIRED_VALIATOR = {
    required: true,
    message: '此项不能为空'
}

export const FORM_LAYOUT = {
    labelCol: {
        span: 4
    },
    wrapperCol: {
        span: 14
    }
}

export const getFormColumns = (id, guaranteeType, form) => {
    const isEdit = !id // 是否可以编辑
    const isGuaranteeInsurance = guaranteeType === GUARANTEE_TYPE_GUARANTEE_INSURANCE // 是否为保险保函
    const baseInfo = [
        {
            fieldCode: 'policyHolderName',
            label: '投保人',
            component: 'AInput',
            extraProps: {
                disabled: true
            }
        },
        {
            fieldCode: 'projectName',
            label: '工程名称',
            component: 'AInput',
            extraProps: {
                disabled: true
            }
        },
        {
            fieldCode: 'guaranteeType',
            label: '保函类型',
            component: 'ASelect',
            rules: [REQUIRED_VALIATOR],
            extraProps: {
                disabled: !isEdit,
                placeholder: '请选择',
                options: GUARANTEE_TYPE_LIST,
                defaultValue: GUARANTEE_TYPE_GUARANTEE_INSURANCE
            }
        }
    ]
    if (!guaranteeType) {
        return [
            baseInfo,
            [],
            [],
            [],
            [],
            [],
            []
        ]
    }
    return [
        baseInfo,

        [
            {
                fieldCode: 'contact',
                label: '经办联系人',
                component: 'AInput',
                rules: [REQUIRED_VALIATOR],
                extraProps: {
                    placeholder: '请输入内容'
                }
            },
            {
                fieldCode: 'mobile',
                label: '经办人手机',
                component: 'AInput',
                rules: [
                    REQUIRED_VALIATOR,
                    {
                        validator: validatePhoneNumber
                    }
                ],
                extraProps: {
                    placeholder: '请输入内容'
                }
            }
        ],

        [
            {
                fieldCode: 'brandName',
                label: isGuaranteeInsurance ? '保险品牌' : '银行品牌',
                component: 'FinancialInstitutionBrandSelect',
                rules: [REQUIRED_VALIATOR],
                extraProps: {
                    optionValue: 'superBrandName',
                    placeholder: '请输入内容',
                    institutionType: isGuaranteeInsurance ? INST_TYPE_INSURANCE : INST_TYPE_BANK
                }
            },
            ...isGuaranteeInsurance ? [
                {
                    fieldCode: 'organizationName',
                    label: '承保出单机构',
                    component: 'AInput',
                    rules: [REQUIRED_VALIATOR],
                    extraProps: {
                        placeholder: '请输入'
                    }
                }
            ] : [
                {
                    fieldCode: 'organizationId',
                    label: '承保出单机构',
                    component: 'FinancialInstitutionSelect',
                    rules: [REQUIRED_VALIATOR],
                    extraProps: {
                        placeholder: '请选择',
                        institutionType: INST_TYPE_BANK,
                        optionValue: 'id',
                        onSelectChange: val => {
                            form.organizationName = val?.companyName
                        }
                    }
                }
            ]
        ],

        [
            {
                fieldCode: 'businessName',
                label: '产品名称',
                component: 'AInput',
                rules: [REQUIRED_VALIATOR],
                extraProps: {
                    disabled: true
                }
            },
            {
                fieldCode: isGuaranteeInsurance ? 'policyNo' : 'guaranteeNo',
                label: isGuaranteeInsurance ? '保单号' : '保函号',
                component: 'AInput',
                rules: [REQUIRED_VALIATOR],
                extraProps: {
                    placeholder: '请输入内容'
                }
            }
        ],

        [
            {
                fieldCode: 'guaranteeAmount',
                label: '保额',
                component: 'AInputNumber',
                rules: [REQUIRED_VALIATOR],
                extraProps: {
                    precision: 2,
                    placeholder: '请输入内容',
                    style: 'width: 100%'
                }
            },
            {
                fieldCode: 'paymentAmount',
                label: isGuaranteeInsurance ? '保费' : '保函手续费',
                component: 'AInputNumber',
                rules: [REQUIRED_VALIATOR],
                extraProps: {
                    precision: 2,
                    placeholder: '请输入内容',
                    style: 'width: 100%'
                }
            }
        ],

        [
            {
                fieldCode: 'deliveryTime',
                label: isGuaranteeInsurance ? '出单日期' : '出函日期',
                component: 'ADatePicker',
                rules: [REQUIRED_VALIATOR],
                extraProps: {
                    format: 'YYYY-MM-DD',
                    valueFormat: 'YYYY-MM-DD',
                    style: 'width: 100%'
                }
            },
            {
                fieldCode: 'guaranteeStartDateAndEndDate',
                label: isGuaranteeInsurance ? '保障期限' : '保函期限',
                component: 'CustomDateRangePicker',
                rules: [REQUIRED_VALIATOR],
                extraProps: {
                    style: 'width: 100%'
                }
            }
        ],

        [
            ...isGuaranteeInsurance ? [
                {
                    fieldCode: 'policyUrl',
                    label: '保单文件',
                    component: 'CustomUploadFile',
                    rules: [REQUIRED_VALIATOR],
                    extraProps: {
                        fileNumber: 1
                    }
                }
            ] : [],
            {
                fieldCode: 'guaranteeUrl',
                label: isGuaranteeInsurance ? '保险凭证' : '保函文件',
                rules: isGuaranteeInsurance ? [] : [REQUIRED_VALIATOR],
                component: 'CustomUploadFile',
                extraProps: {
                    fileNumber: 1
                }
            }
        ]
    ]
}
