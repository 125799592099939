<template>
    <a-card title="保单信息" class="policy-upload-form-card">
        <a-spin :spinning="loading.info">
            <a-form-model
                ref="form"
                v-bind="formLayout"
                :model="form"
                class="policy-upload-form"
            >
                <template v-for="(cols, rowIdx) in formColumns">
                    <a-row :key="rowIdx">
                        <template v-for="col in cols">
                            <a-col :key="col.fieldCode" :span="12">
                                <a-form-model-item
                                    :label="col.label"
                                    :rules="col.rules"
                                    :prop="col.fieldCode"
                                >
                                    <component
                                        :is="col.component"
                                        v-model="form[col.fieldCode]"
                                        v-bind="col.extraProps"
                                    />
                                </a-form-model-item>
                            </a-col>
                        </template>
                    </a-row>
                </template>
            </a-form-model>
        </a-spin>

        <custom-portal :to="portalTo">
            <a-button
                class="policy-upload-form-btn"
                @click.stop="$emit('on-close')"
            >
                取消
            </a-button>

            <template v-if="showRemoveBtn">
                <popconfirm-button
                    button-text="删除"
                    title="确认要执行此操作吗？"
                    :disabled="footerOptBtnDisbled"
                    :loading="loading.remove"
                    class="policy-upload-form-btn"
                    @on-confirm="handleConfirmRemove"
                />
            </template>

            <a-button
                type="primary"
                :disabled="footerOptBtnDisbled"
                :loading="loading.sure"
                class="policy-upload-form-btn"
                @click.stop="validateForm"
            >
                确定
            </a-button>
        </custom-portal>
    </a-card>
</template>
<script>
import {
    Row as ARow,
    Col as ACol,
    Select as ASelect,
    Spin as ASpin,
    Card as ACard,
    Input as AInput,
    Button as AButton,
    FormModel as AFormModel,
    DatePicker as ADatePicker,
    InputNumber as AInputNumber
} from 'ant-design-vue'

import PopconfirmButton from '@components/PopconfirmButton'
import CustomPortal from '@components/CustomPortalVue/Portal'
import CustomUploadFile from '@weights/CreateForm/components/UploadFile'
import CustomDateRangePicker from '@weights/CreateForm/components/DateRangePicker'
import FinancialInstitutionBrandSelect from '@weights/CreateForm/components/FinancialInstitutionBrandSelect'

import { isEmpty } from '@utils'

import { FORM_LAYOUT, getFormColumns } from './config'
import { GUARANTEE_TYPE_GUARANTEE_INSURANCE, GUARANTEE_TYPE_BY_PRODUCT_NAME_MAPPING, GUARANTEE_TYPE_BY_BUSINESSTYPE__MAPPING } from '@constant/enum'

import getUploadInfoService from '@service/marginManagement/marginPaymentDetail/getPolicyUploadInfoService'
import submitUploadInfoService from '@service/marginManagement/marginPaymentDetail/submitPolicyUploadInfoService'
import deleteUploadInfoService from '@service/marginManagement/marginPaymentDetail/deletePolicyUploadInfoService'
import FinancialInstitutionSelect from '@weights/CreateForm/components/FinancialInstitutionSelect'

export const PORTAL_KEY = 'PolicyUploadModal'

export default {
    name: 'PolicyUploadModalContent',
    components: {
        ARow,
        ACol,
        ASpin,
        ACard,
        AInput,
        ASelect,
        AButton,
        AFormModel,
        ADatePicker,
        AInputNumber,
        CustomPortal,
        PopconfirmButton,
        CustomUploadFile,
        CustomDateRangePicker,
        FinancialInstitutionBrandSelect,
        FinancialInstitutionSelect,
        AFormModelItem: AFormModel.Item
    },
    props: {
        orderNo: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            portalTo: PORTAL_KEY,

            form: { },

            /**
             * 按钮的加载状态
             */
            loading: {
                info: true,
                remove: false,
                sure: false
            },

            /**
             * 底部 删除 和 确定 按钮的禁用状态
             */
            footerOptBtnDisbled: true,

            // formColumns: Object.freeze(FORM_COLUMNS),
            formLayout: Object.freeze(FORM_LAYOUT)
        }
    },
    computed: {
        showRemoveBtn() {
            return !isEmpty(this.form.id)
        },
        formColumns() {
            return getFormColumns(this.form.id, this.form.guaranteeType, this.form)
        }
    },
    watch: {
        orderNo: {
            handler(orderNo) {
                this.getUploadInfo(orderNo)
            },
            immediate: true
        },
        'form.guaranteeType': {
            handler(guaranteeType) {
                if (!this.form.id) { // 该情况可以编辑(动态变更产品名称)
                    this.form = {
                        ...this.form,
                        businessType: GUARANTEE_TYPE_BY_BUSINESSTYPE__MAPPING[guaranteeType],
                        businessName: GUARANTEE_TYPE_BY_PRODUCT_NAME_MAPPING[guaranteeType],
                        organizationName: undefined
                    }
                }
            }
        }
    },
    methods: {
        async getUploadInfo(orderNo) {
            try {
                this.$set(this.loading, 'info', true)

                // eslint-disable-next-line
                const info = await getUploadInfoService({ orderNo }) || {}
                const copiedInfo = {
                    // guaranteeType: GUARANTEE_TYPE_GUARANTEE_INSURANCE // 默认保函类型
                }
                Object.keys(info).forEach(key => {
                    const val = info[key]

                    if (!isEmpty(val)) {
                        copiedInfo[key] = val
                    }
                })
                this.form = copiedInfo
                this.footerOptBtnDisbled = false
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.$set(this.loading, 'info', false)
            }
        },
        // 确定
        async handlerSure() {
            try {
                this.$set(this.loading, 'sure', true)

                const formData = {
                    ...this.form,
                    orderNo: this.orderNo,
                    policyUrl: this.form.guaranteeType === GUARANTEE_TYPE_GUARANTEE_INSURANCE ? this.form.policyUrl : undefined
                }

                await submitUploadInfoService(formData)

                this.$emit('on-close')
                this.$emit('on-refresh')
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.$set(this.loading, 'sure', false)
            }
        },
        // 删除
        async handleConfirmRemove() {
            try {
                this.$set(this.loading, 'remove', true)

                // eslint-disable-next-line
                await deleteUploadInfoService({ id: this.form.id, guaranteeType: this.form.guaranteeType })

                this.$emit('on-close')
                this.$emit('on-refresh')
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.$set(this.loading, 'remove', false)
            }
        },
        validateForm() {
            this.$refs.form.validate(valid => {
                valid && this.handlerSure()
            })
        }
    }
}
</script>
<style lang="scss">
.policy-upload-form {
    .ant-form-item {
        &-label {
            min-width: 120px;
        }
    }

    &-btn + &-btn {
        margin-left: 8px;
    }
}
</style>
