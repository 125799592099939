import axios from '@service'
import { DELETE_POLICY_UPLOAD_INFO } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

/** 删除保单上传信息 */
export default async function deletePolicyUploadInfoService(payload = {}, config = {}) {
    const param = {
        ...DELETE_POLICY_UPLOAD_INFO,
        ...config,
        params: payload
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}

